/* globals  __CLIENT__ */

import { createStore, combineReducers } from 'redux';

import reducers from 'reducers';

export default function () {
  return createStore(
    combineReducers(reducers), 
    __CLIENT__ && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() || undefined
  );
}