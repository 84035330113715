import React, { Component } from 'react';

import './index.css';

import get from 'lodash/get';

import {
  createClient
} from 'contentful';

import PricePDF from './PricePDF';
import ConditionsTable from './ConditionsTable';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = { sanatorium: {} };

    this.cf = createClient({
      space: 'wpk8fz5zicme',
      accessToken: '3be3b7d6298ad1a969f7183cac600ea21a2d476b2eae37c1a5842cdec0af0048'
    });
  }

  componentDidMount() {
    this.cf
      .getEntry(
        this.props.match.params.id, 
        { 
          // eslint-disable-next-line camelcase
          content_type: 'sanatoriums',
          select: 'fields.vouchers,fields.conditions,fields.serviceInfo',
          include: 1 
        }
      )
      .then(
        ((sanatorium) => (
          this.setState({ sanatorium })
        ))
      );
  }
  
  render() {
    const { sanatorium } = this.state;

    const pdfURL = get(
      sanatorium,
      'fields.vouchers[0].fields.prices[0].fields.file.url',
      ''
    );

    const conditions = get(sanatorium, 'fields.conditions.fields');
    const serviceInfo = get(sanatorium, 'fields.serviceInfo.fields');
    
    return (
      <div>
        {conditions && <ConditionsTable serviceInfo={serviceInfo} conditions={conditions} />}
        <PricePDF
          url={pdfURL}
        />
      </div>
    );
  }
}

export default Main;