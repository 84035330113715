import React, { Component } from 'react';

import {
  Document, Page
} from 'react-pdf/dist/entry.webpack';

import { map, range } from 'lodash';

class PricePDF extends Component {
  constructor(props) {
    super(props);
    this.state = { document: {} };
  }

  render() {
    const { url } = this.props;

    const {
      numPages
    } = this.state.document;

    window.myDoc = this.state.document;

    return (
      <div>
        <Document
          file={url}
          onLoadSuccess={(document) => this.setState({ document })}
        >
          {
            map(
              numPages ? range(1, numPages + 1) : [],
              (page) => <Page scale={1} pageNumber={page} key={page} />
            )
          }
        </Document>
      </div>
    );
  }
}

export default PricePDF;