/* globals __SERVER__, __CLIENT__ */
import React from 'react';

import { Provider } from 'react-redux';

import { Router, StaticRouter, Switch, Route } from 'react-router-dom';

import history from 'helpers/history';
import routes from 'routes';
import createStore from 'store';

const store = createStore();

const RouteWithSubRoutes = ({ name, ...route }) => (
  <Route {...route} key={name} />
);

const AppRouter = ({ history, children, location }) => {
  if (__CLIENT__)
    return (
      <Router history={history}>{children}</Router>
    );
  if (__SERVER__)
    return (
      <StaticRouter location={location} context={{}}>{children}</StaticRouter>
    );
};

const App = ({ location }) => (
  <Provider store={store}>
    <AppRouter history={history} location={location}>
      <Switch>
        {routes.map((route) => RouteWithSubRoutes(route))}
      </Switch>
    </AppRouter>
  </Provider>
);

export default App;