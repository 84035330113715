import React, { Fragment } from 'react';

const ConditionsTable = ({ serviceInfo, conditions }) => (
  <table className='conditionsTable'>
    <tbody>
      <tr>
        <td>Заезд</td>
        <td>{conditions.checkInTime}</td>
      </tr>
      <tr>
        <td>Выезд</td>
        <td>{conditions.departureTime}</td>
      </tr>
      <tr>
        <td>Отмена</td>
        <td>{conditions.cancel}</td>
      </tr>
      <tr>
        <td>Расчёт проживания</td>
        <td>{conditions.accommodation}</td>
      </tr>
      <tr>
        <td>Методы оплаты</td>
        <td>{conditions.paymentMethod.join(', ')}</td>
      </tr>
      {
        serviceInfo && serviceInfo.selfBookingConditions &&
          <tr>
            <td>Условия при бронировании напрямую</td>
            <td dangerouslySetInnerHTML={{ 
              __html: serviceInfo.selfBookingConditions.replace(/\n/g, '<br />')
            }}/>
          </tr>
      }
      <tr>
        <td>Карты</td>
        <td>{conditions.bankCard.join(', ')}</td>
      </tr>
      <tr>
        <td>Наличными в кассу при заезде?</td>
        <td>{conditions.cash ? 'да' : 'нет'}</td>
      </tr>
      <tr>
        <td>Условия бронирования для гостя напрямую в санаторий</td>
        <td>{conditions.orderSanatorium}</td>
      </tr>
      <tr>
        <td>Размещение детей</td>
        <td>
          <p>
            с {conditions.childrenAge} лет 
            <br /> 
            {conditions.children.split('\n').map((item, key) => (
              <Fragment key={key}>{item}<br /></Fragment>
            ))}
          </p>
        </td>
      </tr>
    </tbody>
  </table>
);

export default ConditionsTable;